<template>
  <div class="reporting management-page">
    <div class="title" style="color: #383838; font-size: 24px;">Payouts</div>
    <AgGrid
      :apiurl="apiUrl"
      :side-bar=true
      :define-defs="columnDefinitionsMainTable"
      group-panel=''
      :excel-options="{fileName:'Payouts.xlsx'}"
      height="88%"
      @filter-changed="filterModified"
    >
    </AgGrid>
    <!-- Modal for Settlement Group Details -->
    <base-modal v-model="showModal" :is-large="true" title="Settlement Group">
      <div class="row" style="margin-top: 35px">
        <div class="col-6">
          <p><strong>Payout ID:</strong> {{ selectedGroup.payout_details.id }}</p>
        </div>
        <div class="col-6">
          <p><strong>Processor:</strong> {{ selectedGroup.payout_details.processor }}</p>
        </div>
      </div>
      <div class="row" style="margin-top: 10px">
        <div class="col-6">
          <p><strong>Status:</strong> {{ selectedGroup.payout_details.status }}</p>
        </div>
        <div class="col-6">
          <p><strong>Payout Amount:</strong> {{ selectedGroup.payout_details.amount }}</p>
        </div>
      </div>
      <div class="row" style="margin-top: 10px">
        <div class="col-12">
          <ag-grid-vue
            class="ag-theme-balham table mt-2"
            :column-defs="columnDefinitionsModal"
            :row-data="settledTransactions"
            :pagination="false"
            :pagination-page-size="200"
            :immutable-data="true"
            @grid-ready="onGridReady"
          />
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import AgGrid from '@/components/AgGrid.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import { AgGridVue } from 'ag-grid-vue3';
import http from '@/services/http';
import { computedAsync } from '@vueuse/core';

/**
 * @typedef {{
 *   id: string
 *   auth_amount: number
 *   net_amount: number
 *   att_num: string
 *   transaction_date: string
 *   fund_date: string
 * }} PayoutTransactionDetailModel
 */

/**
 * @typedef {{
 *   id: string
 *   is_deleted: Boolean
 *   created_at: string
 *   updated_at: string
 *   amount: number
 *   pending_amount: number
 *   destination_bank_account_number: string
 *   destination_bank_routing_number: string
 *   api_response: Record<string, *> & {details: Record<string, string>}
 *   client_id: string
 *   status: string
 *   processor: string
 *   settlement_group_id?: string
 * }} PayoutDetailModel
 */

/**
 * @typedef {{
 *   id: string
 *   is_deleted: Boolean
 *   created_at: string
 *   updated_at: string
 *   affiliate_id?: string
 *   affiliate_name: string
 *   account_num: string
 *   transaction_date: string
 *   fund_date: string
 *   settle_date: string
 *   att_num: string
 *   trans_description: string
 *   transaction_detail_account: string
 *   transaction_detail_type: string
 *   auth_amount: number
 *   gross_amount: number
 *   disc_fee: number
 *   per_trans_fee: number
 *   credit: number
 *   net_amount: number
 *   transaction_detail_name: string
 *   transaction_detail_email: string
 *   inv_num: string
 *   comment1: string
 *   comment2: string
 *   avs_code: string
 *   avs_code_description: string
 *   response_code: string
 *   response_code_description: string
 *   auth_code: string
 *   exp_date?: string
 *   user_name: string
 *   transaction_info_id: string
 *   sweep_id: string
 *   batch_id: string
 *   card_present: Boolean
 *   has_cvv2: string
 *   cvv2_resp: string
 *   billing_address1: string
 *   billing_address2: string
 *   billing_address3: string
 *   billing_city: string
 *   billing_state: string
 *   billing_postal_code: string
 *   billing_country: string
 *   shipping_address1: string
 *   shipping_address2: string
 *   shipping_city: string
 *   shipping_state: string
 *   shipping_postal_code: string
 *   shipping_name: string
 *   owner_first_name: string
 *   owner_last_name: string
 *   owner_address1: string
 *   owner_address2: string
 *   owner_address3: string
 *   owner_city: string
 *   owner_state: string
 *   owner_postal_code: string
 *   owner_country_code: string
 *   bank_name: string
 *   bank_address1: string
 *   bank_address2: string
 *   bank_city: string
 *   bank_state_province: string
 *   bank_postal_code: string
 *   bank_telephone: string
 *   sending_payer_name: string
 *   sending_payer_email: string
 *   sending_account_num: string
 *   receiving_payer_name: string
 *   receiving_payer_email: string
 *   receiving_account_num: string
 *   first_name: string
 *   last_name: string
 *   email: string
 *   business_legal_name: string
 *   doing_business_as: string
 *   card_number: string
 *   auth_currency_code: string
 *   parent_transaction_info_id: string
 *   merchant_descriptor: string
 *   nacha_effective_entry_date?: string
 *   trace_number: string
 *   card_present_type: string
 *   merchant_transaction_date: string
 *   merchant_fund_date: string
 *   merchant_settle_date: string
 *   gateway_transaction_id: string
 *   arn: string
 *   external_id: string
 *   reporting_payout_id: string
 * }} PayoutTransactionDetailModel
 */

/**
 * @typedef {{
 *   merchant_name: string
 *   amount: string
 *   balance: string
 *   payout_date: string
 *   transaction_details: Array<PayoutTransactionDetailModel>
 *   payout_details: PayoutDetailModel
 *   payout_transaction_details: PayoutTransactionDetailModel
 * }} PayoutApiModel
 */

/**
 * @typedef {{
 *   id: string
 *   client: {
 *     id: string
 *     name: string
 *   }
 *   settlement_group: {
 *     id: string
 *     settlement_group_id: string
 *     processor: string
 *     batch_id: string
 *     start_date: string
 *     end_date: string
 *     total_amount: string
 *   }
 *   amount: number
 *   pending_amount: number
 *   destination_bank_account_number: string
 *   destination_bank_routing_number: string
 *   api_response: Object
 *   processor: string
 *   status: string
 * }} PayoutPaymentModel
 */

const apiUrl = ref(`/api/payouts`);
// const agRef = ref();
// const total = ref(0);
// const selectedPayoutAmount = ref(0);
const showModal = ref(false);
/**
 * @type {import("vue").Ref<PayoutApiModel>}
 */
const selectedGroup = ref({});
const settledTransactions = ref([]);
// const discrepancy = ref(0);

// noinspection JSUnusedGlobalSymbols
/**
 * @type {import("vue").Ref<Promise<number>>}
 */
// eslint-disable-next-line no-unused-vars
let totalDiscrepancy = computedAsync(async () => {
  let totalDiscrepancy = 0;
  const response = await http.get(apiUrl.value);
  /**
   * @type {Array<PayoutApiModel>}
   */
  const payouts = response.data.message;

  console.log(payouts);
  console.log('Payouts are above');

  payouts.forEach((payout) => {
    if (!payout.payout_transaction_details.net_amount) {
      return NaN;
    }
    const payoutAmount = parseFloat(`${payout.amount}`) || 0; // Parse payout amount

    // Helper function to calculate the total settlement amount
    /**
     * @param {PayoutApiModel} p
     * @returns {number}
     */
    const setTotal = (p) => {
      return p.payout_transaction_details.net_amount || 0;
      // let aTotal = 0;
      //
      // // Iterate through settlement group transactions
      // p.settlement_group?.transactions?.forEach((value) => {
      //   if (value?.settlement?.amount) {
      //     aTotal += parseFloat(value?.settlement?.amount) || 0; // Accumulate total settlement amount
      //   }
      // });
      //
      // return aTotal;
    };

    const theTotal = setTotal(payout); // Get total settlement for the current payout
    const result = payoutAmount - theTotal; // Calculate the discrepancy
    console.log(`Payout amount: ${payoutAmount}, Settlement total: ${theTotal}, Discrepancy: ${result}`);

    totalDiscrepancy += result; // Accumulate discrepancy
  });

  console.log(`Total Discrepancy: ${totalDiscrepancy}`);

  return parseFloat(totalDiscrepancy.toFixed(2)); // Return the total discrepancy rounded to 2 decimal places
}, 0);

/*
const aggFuncs = {
  total: (params) => {
    let total = 0;
    console.log(params);
    console.log('params above for the total in aggFuncs');
    params.values.forEach((value) => {
      console.log(value);
      console.log('value above for the total in aggFuncs');
      if (value) {
        total += parseFloat(value) || 0;
      }
    });
    return total.toFixed(2);
  }
};
*/

function onGridReady(params) {
  params.api.sizeColumnsToFit();
  params.api.refreshCells({ force: true });
}

const columnDefinitionsModal = [
  {
    headerName: 'TransactionId',
    field: 'id',
    autoHeight: true,
  },
  {
    headerName: 'Created At',
    field: 'transaction_date',
    autoHeight: true,
  },
  {
    headerName: 'Auth Amount',
    field: 'auth_amount',
    autoHeight: true,
    aggFunc: 'total',
    valueFormatter: (p) => {
      return parseFloat(p.value).toFixed(2);
    },
  },
  {
    headerName: 'Net Amount',
    field: 'net_amount',
    autoHeight: true,
    valueFormatter: (p) => {
      return parseFloat(p.value).toFixed(2);
    },
  },
  {
    headerName: 'Transaction Description',
    field: 'trans_description',
    autoHeight: true,
  },
];

// Open modal with settlement group details

// Close modal
/*
const closeModal = () => {
  showModal.value = false;
};
*/


/*
onMounted(() => {
});
*/

// eslint-disable-next-line no-unused-vars
const filterModified = (ignoredModel) => {
};

const columnDefinitionsMainTable = [
  // { headerName: `Destination Bank Account`,
  //   children: [
  //     {
  //       headerName: 'Merchant',
  //       filter: 'agTextColumnFilter',
  //       valueGetter: (p) => { if(!p.data) return ''; return p.data.client.name;}
  //     },
  //     {
  //       headerName: 'Account Number',
  //       filter: 'agSetColumnFilter',
  //       valueGetter: (p) => { if(!p.data) return ''; return p.data.destination_bank_account_number;},
  //     },
  //     {
  //       headerName: 'Routing Number',
  //       filter: 'agTextColumnFilter',
  //       valueGetter: (p) => { if(!p.data) return ''; return p.data.destination_bank_routing_number;}
  //     }]},
  {
    headerName: 'Payout Activity',

    children: [
      {
        headerName: 'Merchant',
        filter: 'agTextColumnFilter',
        /**
         * @param {{data: PayoutApiModel}} p
         */
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data.merchant_name;
        },
      },
      {
        headerName: 'Payout Date',
        filter: 'agSetColumnFilter',
        /**
         * @param {{data: PayoutApiModel}} p
         */
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data.payout_date;
        },
      },
      {
        headerName: 'Updated At',
        filter: 'agSetColumnFilter',
        /**
         * @param {{data: PayoutApiModel}} p
         */
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data.payout_details.updated_at;
        },
      },
      {
        headerName: 'Pending Amount',
        filter: 'agNumberColumnFilter',
        /**
         * @param {{data: PayoutApiModel}} p
         */
        valueGetter: (p) => {
          if (!p.data) return null;
          return Number(p.data.payout_details.pending_amount).toFixed(2) || 0.00;

        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return p.colDef.valueGetter(p);

        },
      },
      {
        headerName: 'Total Amount',
        filter: 'agNumberColumnFilter',
        aggFunc: 'total',
        /**
         * @param {{data: PayoutApiModel}} p
         */
        valueGetter: (p) => {
          if (!p.data) return null;
          return Number(p.data.payout_details.amount).toFixed(2) || 0.00;

        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return p.colDef.valueGetter(p);
        },
      },
      {
        headerName: 'Processor',
        filter: 'agTextColumnFilter',
        /**
         * @param {{data: PayoutApiModel}} p
         */
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data.payout_details.processor;
        },
      },
      {
        headerName: 'Status',
        filter: 'agTextColumnFilter',
        /**
         * @param {{data: PayoutApiModel}} p
         */
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data.payout_details.status;
        },
      },
    ],
  },
  {
    headerName: `Actions`,
    children: [
      {
        headerName: 'Settlement Group',
        field: 'actions',
        /**
         * @param {{data: PayoutApiModel}} p
         */
        cellRenderer: function(p) {
          if (!p.data?.transaction_details) return 'N/A';
          return `<p style="font-size: 12px; text-decoration: underline;">View Detail</p>`;
        },
        /**
         * @param {{data: PayoutApiModel}} p
         */
        onCellClicked: function(p) {
          // selectedPayoutAmount.value = parseFloat(parseFloat(p.data.payout_details.amount).toFixed(2));
          console.log('PayoutPage debug')
          selectedGroup.value = p.data;
          settledTransactions.value = p.data.transaction_details || [];
          /*
          const setTotal = (p) => {
            total.value = 0;
            p.data.settlement_group?.transactions.forEach((value) => {
              if (value?.settlement?.amount) {
                total.value += parseFloat(value?.settlement?.amount) || 0;
              }
            });
            total.value = parseFloat(Number(total.value).toFixed(2));
          };
          setTotal(p);
          discrepancy.value = parseFloat(Number(selectedPayoutAmount.value - total.value).toFixed(2));
          */
          showModal.value = true;
        },
        width: 150,
        sortable: false,
        filter: false,
      },
    ],
  },
];

</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}

.loader {
  z-index: 2;
}

.table {
  height: 70vh !important;
}

.subs-btn {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}

@media screen and (max-width: 768px) {
  .reporting-filters {
    flex-direction: column;
  }

  .reporting-filters-container {
    flex-direction: column-reverse;
  }

  .report-input {
    width: 50%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

::v-deep(.report-buttons) {
  @media screen and (max-width: 768px) {
    .btn {
      width: 50%;
    }
  }
}
</style>

<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.min.css";

.reporting {
  .status-value {
    &.Failed {
      background: #ffe4e4;
      color: #b3322c;
    }

    &.Success {
      color: var(--c-success);
      background: #ddffef;
    }
  }

  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
