<template>
  <div class="reporting management-page">
    <view-report-modal v-model="showViewModal" :details="showViewDetails" />

    <base-modal v-model="showRefundModal">
      <refund-modal
          :transaction="transactionDetails"
          @update="handleRefundSuccess"
      />
    </base-modal>

    <div class="title" style="color: #383838; font-size: 24px">Reporting</div>
    <div class="mt-3 mb-2 position-relative">
      <!-- Add margin to separate from elements above and below -->
    </div>
    <div v-if="hasMetaFieldSearch && metaFieldSearchTerm" class="row">
      <div class="col-md-9">
        <div class="form-group">
          <label for="searchParameter">Enter Search Parameter:</label>
          <input id="searchParameter" v-model="searchParameter" type="text" class="form-control">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <base-button class="ms-auto search-payee-email" @click="runSearch">
            <span class="fz-16 fw-500 vertical-align-middle"> + </span>
            <span class="vertical-align-middle">Run Search</span></base-button
          >
        </div>
      </div>
    </div>
    <div v-if="hasMetaFieldSearch && metaFieldSearchTerm" class="mt-3 mb-2 position-relative">
      <!-- Add margin to separate from elements above and below -->
    </div>
    <base-spinner v-if="loadingList" class="loader" />
    <AgGrid
        ref="agRef"
        :apiurl="apiUrl"
        :columns="[]"
        :define-defs="columnDefs"
        :side-bar="true"
        :status-bar="statusBar"
        :agg-func="undefined/*aggFunc*/"
        :excel-styles="excelStyles"
        group-panel=""
        :counter="true"
        :excel-options="{ fileName: 'Transaction Report.xlsx' }"
        height="84vh"
        total-column-name="Amount"
        @filter-changed="filterModified"
    ></AgGrid>
  </div>
</template>

<script setup>
import {ref, computed, onBeforeMount} from 'vue';
import AgGrid from '@/components/AgGrid.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import ViewReportModal from '@/components/reporting/ViewReportModal.vue';
import RefundModal from '@/components/reporting/RefundModal.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import { PERMISSION_PRIMITIVES } from '@/config/constants';
import { useStore } from 'vuex';
import http from "@/services/http";
import BaseButton from "@/components/base/BaseButton.vue";

const store = useStore();
const showLocation = computed(() => store.getters.merchantHasPermissions);
const user = computed(() => store.state.user);
const hasPermissions = computed(() => store.getters.merchantHasPermissions);
const userHasCreditCardRefundPermission = computed(() => {
  return user.value?.role?.granted_permissions.some(
      (permission) => permission.name === PERMISSION_PRIMITIVES.TRANSACTION_REFUND
  );
});
const userHasAchRefundPermission = computed(() => {
  return user.value?.role?.granted_permissions?.some(
      (permission) =>
          permission.name === PERMISSION_PRIMITIVES.TRANSACTION_CREDIT_ACH_REFUND
  );
});
const userHasRefundPermission = computed(() => {
  return (
      userHasCreditCardRefundPermission.value || userHasAchRefundPermission.value
  );
});
const loadingList = ref(false);
const hasMetaFieldSearch = ref(false);
const metaFieldSearchTerm = ref('');
const searchParameter = ref('');
const defaultClientId = computed(() => store.getters.defaultClientId);
const showViewModal = ref(false);
const showViewDetails = ref([]);
const transactionDetails = ref(null);
const showRefundModal = ref(false);
const excelStyles = [
  {
    id: 'dateType',
    dataType: 'dateTime',
    numberFormat: { format: 'yyyy-mm-dd hh:mm:ss' }
  }
];
const start = ref(
    new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0]
);
const end = ref(new Date().toISOString().split('T')[0]);

const apiUrl = ref(`/api/v1/report?start=${start.value}&end=${end.value}&snowflake=true`);
/*
// no longer used since `externalFilterChanged` commented out
const filterValue = ref('year to date'.replaceAll(/ /g, ''));
*/
const agRef = ref();
const handleRefundSuccess = () => {
  showRefundModal.value = false;
  transactionDetails.value = null;
};

const statusBar = {};

onBeforeMount(() => {
  http.get('/api/client?id=' + defaultClientId.value).then((data) => {
    const dataMessage = data?.data?.message;
    console.log('Message from api client: ', dataMessage);
    hasMetaFieldSearch.value = dataMessage?.config?.meta_field_search || false;
    metaFieldSearchTerm.value = dataMessage?.config?.meta_field_search_term || '';
  });
});

/*
const filterOptions = {
  5: {
    values: [
      'cc',
      'charge',
      'terminal',
      'void',
      'settle',
      'refund',
      'subscription',
      'debit',
      'credit'
    ],
    filterType: 'set'
  }
};
*/

const filterModified = (model) => {
  console.log('REPORTING: filterModified function called with model: ', model);
  const dates = model[1];
  console.log('REPORTING: dates extracted from model: ', dates);
  start.value = dates?.dateFrom?.split(' ')[0];
  end.value = dates?.dateTo?.split(' ')[0];
  console.log('REPORTING: start and end values set: ', start.value, end.value);
  // todo make timestamps
  if (start.value && end.value) {
    apiUrl.value = `/api/v1/report?start=${start.value}&end=${end.value}&snowflake=true`;
    agRef.value.refreshAPI(`/api/v1/report?start=${start.value}&end=${end.value}&snowflake=true`);
  }
};

async function runSearch() {
  const param = searchParameter.value;
  loadingList.value = true;
  try {
    console.log('REPORTING: start and end values set: ', start.value, end.value);
    // todo make timestamps
    if (start.value && end.value) {
      const theApiUrl = `/api/v1/report?start=${start.value}&end=${end.value}&${metaFieldSearchTerm.value}=${param}`
      apiUrl.value = theApiUrl;
      agRef.value.refreshAPI(theApiUrl);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loadingList.value = false;
  }
}

/*
const externalFilterChanged = () => {
  const currentDate = new Date();
  const today = new Date().toISOString().split('T')[0];
  switch (filterValue.value) {
    case 'past7days':
      start.value = new Date(currentDate.getTime() - 8 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      end.value = today;
      break;
    case 'past30days':
      start.value = new Date(currentDate.getTime() - 31 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      end.value = today;
      break;
    case 'yeartodate':
      start.value = new Date(currentDate.getFullYear(), 0, 1)
        .toISOString()
        .split('T')[0];
      end.value = today;
      break;
    case 'lastmonth':
      start.value = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      )
        .toISOString()
        .split('T')[0];
      end.value = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
        .toISOString()
        .split('T')[0];
      break;
    case 'monthtodate':
      start.value = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      )
        .toISOString()
        .split('T')[0];
      end.value = today;
      break;
    default:
      start.value = new Date(currentDate.getTime() - 8 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      end.value = today;
  }
  apiUrl.value = `/api/v1/report?&start=${start.value}&end=${end.value}`;
  agRef.value.refreshAPI(`/api/v1/report?&start=${start.value}&end=${end.value}`);
};
*/

// noinspection JSUnusedGlobalSymbols
const columnDefs = computed(() => [
  {
    headerName: `Transaction Details`,
    children: [
      {
        headerName: 'Date',
        cellClass: 'dateType',
        valueGetter: (p) => {
          if (!p.data) return '';
          return new Date(p.data[0]).toISOString();
        },
        valueFormatter: (p) => {
          if (!p.data) return '';
          let x = new Date(Date.parse(p.data[0]));
          return x.toLocaleString('en-US');
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          filterOptions: ['inRange'],
          inRangeInclusive: true
        },
        sort: 'desc'
      },
      {
        headerName: 'Parent',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[3];
        }
      },
      {
        headerName: 'DBA name',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[2];
        }
      },
      ...((!showLocation.value) ? [] : [{
        headerName: 'Location',
        filter: 'agSetColumnFilter',
        /**
         * @param {{data: ReportingTuple}} p
         */
        valueGetter: (p) => {
          return p?.data?.[1]?.data?.api_response.meta?.["customFields"]?.["Clinic Location"];
        },
      }]),
      {
        headerName: 'ID',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.id;
        },
        hide: true
      },
      {
        headerName: 'Method',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.type;
        }
      },
      {
        headerName: 'Authorized',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.payment_status;
        },
        cellStyle: function (p) {
          if (!p.data) return '';
          // noinspection EqualityComparisonWithCoercionJS
          if (
              p.data[1]?.payment_status == false ||
              p.data[1]?.payment_status == 'false' ||
              p.data[1]?.payment_status == 'False'
          ) {
            //Here you can check the value and based on that you can change the color
            //mark police cells as red
            return { color: '#a87e71' };
          } else {
            return { color: '#86a871' };
          }
        }
      },
      {
        headerName: 'Status',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.status;
        },
      },
      {
        headerName: 'Message',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.action;
        },
        hide: true
      },
      {
        headerName: 'Amount',
        filter: 'agNumberColumnFilter',
        field: 'amount',
        aggFunc: 'total',
        /**
         * @param {{data: ReportingTuple}} p
         */
        valueGetter: (p) => {
          if (!p.data) return null;
          // oh my god
          const theData = p.data[1];
          const theStatus = theData?.payment_status || "False";
          if (theStatus === "False") {
            return null;
          }

          const theAction = theData?.action_mode || null;
          if (theAction === null) {
            return null;
          }

          const typesToTotal = [
            "settle", "debit", "charge", "apple_pay"
          ];

          const typesToSubtract = [
            "refund", "void", "blind_refund", "reversal", "credit"
          ];

          const theAmount = parseFloat(theData?.amount) || 0.0;

          if (typesToSubtract.includes(theAction)) {
            return -theAmount;
          }

          if (!typesToTotal.includes(theAction)) {
            return null;
          }

          return theAmount;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          // do not use the value from backend, use value as computed above
          // return parseFloat(p.data[1]?.amount).toFixed(2) || 0.0;
          const value = p.colDef.valueGetter(p);
          return (parseFloat(value) || 0.00).toFixed(2);
        }
      },
      {
        headerName: 'Fee',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.fee;
        },
      },
      {
        headerName: 'Surcharge',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '0.00';
          return p.data[1]?.surcharge;
        },
      },
      {
        headerName: 'Card Brand',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return 'n/a';
          return p.data[1]?.card_brand;
        },
      },
      {
        headerName: 'Card Type',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return 'n/a';
          return p.data[1]?.card_type;
        },
      },
      {
        headerName: 'Settlement',
        filter: 'agNumberColumnFilter',
        valueGetter: p => {
          console.log('Settlement', p.data[1]?.settlement?.amount);
          const settlementAmount = p.data[1]?.settlement?.amount;
          return settlementAmount ? parseFloat(settlementAmount).toFixed(2) : 'n/a';
        }
      },
      {
        headerName: 'Settlement Date',
        cellClass: 'dateType',
        valueGetter: (p) => {
          if (!p.data[1]?.settlement?.date) return 'n/a';
          return new Date(p.data[1]?.settlement?.date).toISOString().split('T')[0];
        },
        valueFormatter: (p) => {
          if (!p.data[1]?.settlement?.date) return 'n/a';
          let x = new Date(Date.parse(p.data[1]?.settlement?.date));
          return x.toLocaleDateString('en-US');
        },
      },
      /*
      {
        headerName: 'Disbursement',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return (
            parseFloat(p.data[1]?.amount || 0) - parseFloat(p.data[1]?.fee || 0)
          ).toFixed(2);
        },
        hide: true
      }
      */
      // {
      //   headerName: 'Card',
      //   filter: 'agSetColumnFilter',
      //   valueGetter: (p) => {
      //     if (!p.data || !p.data[1]) return 'N/A';

      //     const txDetails = p.data[1]?.details;

      //     let card =
      //       txDetails?.ExtData?.CardType ||
      //       txDetails?.creditCard?.cardType ||
      //       txDetails?.Card?.CardLogo ||
      //       'N/A';
      //     if (card.toUpperCase() === 'OTHER') {
      //       card = 'UNRECOGNIZED';
      //     }
      //     return card.toUpperCase();
      //   },
      //   hide: false
      // }
    ]
  },
  {
    headerName: 'Customer',
    children: [
      {
        headerName: 'First Name',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.data?.api_response?.meta?.firstName;
        },
        hide: true
      },
      {
        headerName: 'Last Name',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.data?.api_response?.meta?.lastName;
        },
        hide: true
      },
      {
        headerName: 'Email',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1]?.data?.api_response?.meta?.email;
        },
        hide: true
      }
    ]
  },
  {
    headerName: 'Actions',
    children: [
      {
        headerName: 'Open',
        filter: false,
        field: 'view',
        cellRenderer: (p) => {
          if (p.data && p.data[1]?.amount)
            return `<p style="font-size: 12px; text-decoration: underline;">Open</p>`;
          return '';
        },
        onCellClicked: function (params) {
          showViewDetails.value = params.node.data;
          showViewModal.value = true;
        }
      },
      {
        headerName: 'Refund',
        filter: false,
        field: 'refund',
        hide: hasPermissions.value && !userHasRefundPermission.value,
        cellRenderer: function (p) {
          const returnLink = `<p style="font-size: 12px; text-decoration: underline;">Refund</p>`;
          if (!p.data) return '';
          const txData = p.data[1];
          // noinspection EqualityComparisonWithCoercionJS
          if (
              (txData?.type !== 'refund' &&
                  txData?.type !== 'void' &&
                  txData?.type !== 'batch' &&
                  txData?.type !== 'credit' &&
                  txData?.type !== 'authorize' &&
                  txData?.request_status === true &&
                  txData?.payment_status !== false) ||
              txData?.payment_status != 'false' ||
              txData?.payment_status != 'False'
          ) {
            if (hasPermissions.value) {
              if (
                  txData?.type === 'debit' &&
                  userHasAchRefundPermission.value
              ) {
                return returnLink;
              } else if (
                  (txData?.type === 'credit' ||
                      txData?.type === 'cc' ||
                      txData?.type === 'authorize') &&
                  userHasCreditCardRefundPermission.value
              ) {
                return returnLink;
              } else {
                return '';
              }
            }
            return returnLink;
          }
          return '';
        },
        onCellClicked: function (p) {
          if (!p.data) return '';
          const txData = p.data[1];
          // noinspection EqualityComparisonWithCoercionJS
          if (
              (txData?.type !== 'refund' &&
                  txData?.type !== 'void' &&
                  txData?.type !== 'batch' &&
                  txData?.type !== 'credit' &&
                  txData?.type !== 'authorize' &&
                  txData?.request_status === true &&
                  txData?.payment_status === true) ||
              txData?.payment_status == 'true' ||
              (txData?.payment_status == 'True' && Number(txData?.amount))
          ) {
            if (hasPermissions.value) {
              if (
                  txData?.type === 'debit' &&
                  userHasAchRefundPermission.value
              ) {
                transactionDetails.value = txData;
                showRefundModal.value = true;
              } else if (
                  (txData?.type === 'credit' || txData.type === 'cc') &&
                  userHasCreditCardRefundPermission.value
              ) {
                transactionDetails.value = txData;
                showRefundModal.value = true;
              }
            } else {
              transactionDetails.value = txData;
              showRefundModal.value = true;
            }
          }
        }
      }
    ]
  }
]);
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.loader {
  z-index: 2;
}

.table {
  height: 70vh !important;
}

.subs-btn {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}

@media screen and (max-width: 768px) {
  .reporting-filters {
    flex-direction: column;
  }

  .reporting-filters-container {
    flex-direction: column-reverse;
  }

  .report-input {
    width: 50%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

::v-deep(.report-buttons) {
  @media screen and (max-width: 768px) {
    .btn {
      width: 50%;
    }
  }
}
</style>

<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-balham.min.css';
.reporting {
  .status-value {
    &.Failed {
      background: #ffe4e4;
      color: #b3322c;
    }

    &.Success {
      color: var(--c-success);
      background: #ddffef;
    }
  }

  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
