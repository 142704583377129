<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import axios from 'axios';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { useRoute, useRouter } from 'vue-router'; // Import useRoute

import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { processUserPermissions } from '@/pages/VirtualTerminal-utils';

const store = useStore();
const toast = useToast();
const router = useRouter();
const route = useRoute();

// const props = defineProps({
//   apikey: {
//     type: String,
//     required: true
//   }
// });
const isSwipeOnly = ref(false);
const isSwipeAndCNP = ref(false);
/**
 * @type {import("vue").Ref<UnwrapRef<boolean | null>>}
 */
const isMissingRbacPermissions = ref(null);
const iframeLink = ref('');
const loading = ref(true);
const params = ref('');
const url = ref('');
const userId = ref('');
const merchantId = ref('');

/**
 * @typedef {{
 *   id: string
 *   order: int
 *   name: string
 *   type: string
 *   values?: Array<string>
 *   required: boolean
 *   is_hidden: boolean
 *   name: string
 * }} CustomField
 */

/**
 * @type {import("vue").Ref<Array<CustomField>>}
 */
const customFields = ref([]);
// const locations = ref([]);

// import http from "@/services/http";

const user = computed(() => store.state.user);
const permissions = computed(() => store.state.permissions);
const merchantHasPermissions = computed(
  () => store.getters.merchantHasPermissions
);

const apikey = route.params.apikey;
// Get the full URL of the current page
const windowUrl = window.location.href;
// Create a URL object
const urlObj = new URL(windowUrl);
// Use URLSearchParams to get the query parameters
const urlSearchParams = new URLSearchParams(urlObj.search);
console.log("urlSearchParams: ", urlSearchParams);
// Access specific parameters
isSwipeOnly.value = urlSearchParams.get('isSwipeOnly');
isSwipeAndCNP.value = urlSearchParams.get('isSwipeAndCNP');
console.log("isSwipeOnly: ", isSwipeOnly);

async function rerunLogic() {
  console.log('Permissions Rendered:', permissions.value);
  console.log('isSwipeOnly Rendered:', isSwipeOnly.value);
  console.log('isSwipeAndCNP Rendered:', isSwipeAndCNP.value);

  try {
    if (!apikey) {
      throw new Error('API key is required');
    }
    if (!url.value) {
      const response = await axios.get('/api/virtualterminalurl', {
        headers: { Authorization: `Bearer ${apikey}` }
      });
      url.value = response.data?.message?.url || '';
      customFields.value = response.data?.message?.customFields || [];
    }

    /**
     * Formatting of the URL Value
     * @type {module:url.URL}
     */
    const urlObj = new URL(url.value);
    const newUrl = new URL(
      urlObj.pathname + urlObj.search,
      window.location.origin
    );
    console.log('New URL with updated origin:', newUrl.href);
    console.log('User roles:', user, user.value?.account?.memberships?.map(e => e.role));

    /**
     * Gets userId and merchantId for iFrame Context
     */
    userId.value = user.value.id;
    merchantId.value = user.value?.account?.default_client?.merchant?.id;

    /**
     * Add userId and merchantId to the iFrame parameters
     * @type {module:url.URLSearchParams}
     */
    const searchParams = new URLSearchParams(newUrl.search);
    const paramsBase64 = searchParams.get('params');
    const paramsObj = JSON.parse(atob(paramsBase64));
    paramsObj.merchantId = merchantId.value;
    paramsObj.userId = userId.value;
    if (isSwipeAndCNP.value) {
      paramsObj.paymentOptions = ['credit_card', 'swipe'];
    }
    console.log('ParamsObj:', paramsObj);

    let rbacResult = processUserPermissions(merchantHasPermissions, store, paramsObj, toast, router, isSwipeOnly);
    isMissingRbacPermissions.value = !rbacResult;

    let role = user.value.account.memberships.filter(m => m.merchant.id === merchantId.value).map(e => e.role).pop();

    if (paramsBase64 && role) {
      if (role.omittable_fields) {
        if (customFields.value) {
          console.log('ParamsObj.customFields:', paramsObj, customFields);
          customFields.value.forEach((field) => {
            if (
              role.omittable_fields.some(
                (omittable_field) => omittable_field.id === field.id
              )
            ) {
              field.required = false;
            }
          });
          paramsObj.customFields = customFields.value;
        }
      }
      if (role.hideable_fields) {
        if (customFields.value) {
          console.log('ParamsObj.customFields:', paramsObj, customFields);
          customFields.value.forEach((field) => {
            if (
                role.hideable_fields.some(
                    (omittable_field) => omittable_field.id === field.id
                )
            ) {
              field.hidden = true;
            }
          });
          paramsObj.customFields = customFields.value;
        }
      }
      if (role.granted_permissions) {
        if (
          role.granted_permissions.some(
            (permission) => permission.name === 'CC Zip'
          )
        ) {
          console.log('User has CC Zip Override permission');
          paramsObj.zipMode = null;
        }
      }
    }

    const updatedParamsBase64 = btoa(JSON.stringify(paramsObj));
    searchParams.set('params', updatedParamsBase64);
    newUrl.search = searchParams.toString();
    iframeLink.value = newUrl.href;
  } catch (error) {
    console.error('Error:', error);

    params.value = btoa(JSON.stringify({ apikey, isVt: true })); // Use the apikey from the URL query
    iframeLink.value = `${window.location.origin}/responsive-iframe/payment?params=${params.value}`;
  } finally {
    loading.value = false;
  }
}

console.log('Permissions Rendered:', permissions.value);

onMounted(async () => {
  await rerunLogic();
});

watch(permissions, (newPermissions) => {
  if (Array.isArray(newPermissions) && newPermissions.length > 0) {
    rerunLogic();
  }
});

</script>
<template>
  <div>
    <div class="top-panel d-flex align-items-center">
      <button class="btn" @click="$router.go(-1)">
        <svg
          viewBox="0 0 16 16"
          class="bi bi-arrow-left"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M15 8a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708.708L2.707 7.5H14.5A.5.5 0 0 1 15 8z"
          />
        </svg>
        Back
      </button>
      <div class="mb-3 ms-auto me-auto">
        <span class="dashboard-title">Virtual Terminal</span>
      </div>
    </div>
    <div v-if="loading || permissions === 'FETCHING'">
      <BaseSpinner />
    </div>

    <div v-else>
      <div v-if="isMissingRbacPermissions">
        <div id="shroud" style="position: relative; pointer-events: none;">
          <div id="shroud-inner" style="position: absolute; width: 100%; height: 100vh; background-color: rgba(255,181,181,0.17); border-radius: 25px;">
          </div>
          <iframe
            :src="iframeLink"
            style="width: 100%; height: 100vh"
            frameborder="0"
          >
          </iframe>
          </div>
      </div>
      <div v-else>
        <iframe
          :src="iframeLink"
          style="width: 100%; height: 100vh"
          frameborder="0"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.top-panel {
  display: flex;
  /* justify-content: space-between;
  align-items: center; */
}
.dashboard-title {
  font-size: 24px;
  color: #383838;
  /* margin-bottom: 10px; */
}
</style>
