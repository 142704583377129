import { PERMISSION_PRIMITIVES } from '@/config/constants';

/**
 * handle RBAC behavior
 *
 * @param {import("vue").ComputedRef<boolean>} merchantHasPermissions
 * @param {import("vuex").Store<StoreState>} store
 * @param {Object} paramsObj
 * @param {import("vue-toastification").ToastInterface} toast
 * @param {import("vue-router").Router} router
 * @param {import("vue").Ref<boolean>} isSwipeOnly
 * @return {boolean} whether the user has permission
 */
export function processUserPermissions(merchantHasPermissions, store, paramsObj, toast, router, isSwipeOnly) {
  if (merchantHasPermissions.value && !!store.state.user) {
    paramsObj.billingAddress = false;
    // const default_options = ['credit_card', 'bank', 'swipe'];
    const permissionSet = new Set(store.getters.grantedPermissions)
    console.log(
      'ALL PERMISSIONS FOR ROLE:',
      permissionSet
    );

    /**
     * These come in from the pre-configured virtual terminal options.
     * In order to render a payment option, all three must be true:
     *
     *  * It is not swipe only configuration (this only ever shows swipe)
     *  * The payment option is included in the originalPaymentOptions
     *  * The user has the relevant permission to view this option
     *
     * @type {*[]}
     */
    const originalPaymentOptions = paramsObj.paymentOptions;
    paramsObj.paymentOptions = [];

    if (!permissionSet.has(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL)) {
      toast.error('User does not have VIRTUAL_TERMINAL permission.');

      return false;
    }

    if (permissionSet.has(PERMISSION_PRIMITIVES.TRANSACTION_CHARGE) && !isSwipeOnly.value && originalPaymentOptions.includes('credit_card')) {
      paramsObj.paymentOptions.push('credit_card');
    }
    if (
      permissionSet.has(PERMISSION_PRIMITIVES.TRANSACTION_DEBIT_ACH_CHARGE) && !isSwipeOnly.value && originalPaymentOptions.includes('bank')
    ) {
      paramsObj.paymentOptions.push('bank');
    }

    // TODO: add permission to get cash
    if (
      !isSwipeOnly.value && originalPaymentOptions.includes('cash')
    ) {
      paramsObj.paymentOptions.push('cash');
    }

    if (isSwipeOnly.value || permissionSet.has(PERMISSION_PRIMITIVES.TRANSACTION_SWIPE) && originalPaymentOptions.includes('swipe')) {
      paramsObj.paymentOptions.push('swipe');
    }

    if (permissionSet.has(PERMISSION_PRIMITIVES.TRANSACTION_APPLE_PAY) && !isSwipeOnly.value && originalPaymentOptions.includes('apple_pay')) {
      paramsObj.paymentOptions.push('apple_pay');
    }

    if (permissionSet.has(PERMISSION_PRIMITIVES.TRANSACTION_GOOGLE_PAY) && !isSwipeOnly.value && originalPaymentOptions.includes('google_pay')) {
      paramsObj.paymentOptions.push('google_pay');
    }

    if (paramsObj.paymentOptions.length === 0) {
      toast.error(
        'User does not have permissions to use any payment methods.'
      );

      // router.push('/');
      return false;
    }

    if (permissionSet.has(PERMISSION_PRIMITIVES.OMIT_CC_ZIP)) {
      paramsObj.zipMode = null;
    }
  }

  return true;
}
